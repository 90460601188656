"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Enumeration representing special symbols in IO.
 */
var Symbols;
(function (Symbols) {
    Symbols["CURLY_OPEN"] = "{";
    Symbols["CURLY_CLOSE"] = "}";
    Symbols["BRACKET_OPEN"] = "[";
    Symbols["BRACKET_CLOSE"] = "]";
    Symbols["COLON"] = ":";
    Symbols["COMMA"] = ",";
    Symbols["HASH"] = "#";
    Symbols["DOUBLE_QUOTE"] = "\"";
    Symbols["SINGLE_QUOTE"] = "'";
    Symbols["BACKSLASH"] = "\\";
    Symbols["TILDE"] = "~";
    Symbols["PLUS"] = "+";
    Symbols["MINUS"] = "-";
    Symbols["DOLLAR"] = "$";
})(Symbols || (Symbols = {}));
exports.default = Symbols;
