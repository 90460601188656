"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SectionCollection {
    constructor() {
        this._sections = [];
        this._sectionNames = {};
        return new Proxy(this, proxy);
    }
    get sections() {
        return this._sections;
    }
    get length() {
        return this._sections.length;
    }
    get(nameOrIndex) {
        if (typeof nameOrIndex === 'string') {
            const index = this._sectionNames[nameOrIndex];
            if (index === undefined) {
                return undefined;
            }
            return this._sections[index];
        }
        return this._sections[nameOrIndex];
    }
    push(section) {
        if (section.name !== undefined) {
            this._sectionNames[section.name] = this._sections.length;
        }
        this._sections.push(section);
    }
    /**
     * Makes the SectionCollection iterable, yielding key-value pairs.
     */
    *[Symbol.iterator]() {
        for (const section of this._sections) {
            yield section;
        }
    }
}
const proxy = {
    get: (target, property) => {
        // If the property is a member of the InternetObject, return it
        if (property in target) {
            return Reflect.get(target, property);
        }
        if (typeof property === 'string') {
            // If the property is a number, get the value at that index
            if (/^[0-9]+$/.test(property)) {
                return target.get(Number(property));
            }
            // Return the string-keyed value
            return target.get(property);
        }
    },
    set: (target, property, value) => {
        throw new Error('Cannot set a value on a SectionCollection');
    }
};
exports.default = SectionCollection;
