"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ErrorCodes;
(function (ErrorCodes) {
    // General
    ErrorCodes["invalidType"] = "invalid-type";
    // tokenNotReady = 'token-not-ready',
    ErrorCodes["invalidValue"] = "invalid-value";
    ErrorCodes["invalidArray"] = "invalid-array";
    ErrorCodes["invalidObject"] = "invalid-object";
    ErrorCodes["valueRequired"] = "value-required";
    ErrorCodes["nullNotAllowed"] = "null-not-allowed";
    ErrorCodes["invalidSchemaName"] = "invalid-schema-name";
    // notAllowed = 'not-allowed',
    // Parser Errors (Header)
    ErrorCodes["unexpectedToken"] = "unexpected-token";
    ErrorCodes["expectingBracket"] = "expecting-bracket";
    // unexpectedColon = 'unexpected-colon',
    // multipleHeaders = 'multiple-headers-found',
    // invalidCollection = 'invalid-collection',
    // invlidHeader = 'invalid-header',
    // invalidHeaderItem = 'invalid-header-item',
    // expectingSeparator = 'expecting-a-separator',
    // openBracket = 'open-bracket',
    // invalidBracket = 'invalid-bracket',
    // incompleteEscapeSequence = 'incomplete-escape-sequence',
    ErrorCodes["unexpectedPositionalMember"] = "unexpected-positional-member";
    ErrorCodes["additionalValuesNotAllowed"] = "additional-values-not-allowed";
    // Parser Errors (Schema)
    ErrorCodes["invalidSchema"] = "invalid-schema";
    ErrorCodes["schemaNotFound"] = "schema-not-found";
    ErrorCodes["schemaMissing"] = "schema-missing";
    ErrorCodes["unknownMember"] = "unknown-member";
    ErrorCodes["duplicateMember"] = "duplicate-member";
    ErrorCodes["emptyMemberDef"] = "empty-memberdef";
    ErrorCodes["invalidDefinition"] = "invalid-definition";
    ErrorCodes["invalidKey"] = "invalid-key";
    ErrorCodes["invalidMemberDef"] = "invalid-memberdef";
    // Variables
    ErrorCodes["variableNotDefined"] = "variable-not-defined";
    ErrorCodes["schemaNotDefined"] = "schema-not-defined";
    // Array
    ErrorCodes["notAnArray"] = "not-an-array";
    // String
    ErrorCodes["notAString"] = "not-a-string";
    // invalidChar = 'invalid-char',
    ErrorCodes["invalidEmail"] = "invalid-email";
    ErrorCodes["invalidUrl"] = "invalid-url";
    ErrorCodes["stringNotClosed"] = "string-not-closed";
    ErrorCodes["invalidEscapeSequence"] = "invalid-escape-sequence";
    ErrorCodes["unsupportedAnnotation"] = "unsupported-annotation";
    // -- string, number, date, datetime, time
    // valueNotInChoice = 'value-not-in-choice',
    ErrorCodes["invalidChoice"] = "invalid-choice";
    // -- string, array
    ErrorCodes["invalidLength"] = "invalid-length";
    ErrorCodes["invalidMinLength"] = "invalid-min-length";
    ErrorCodes["invalidMaxLength"] = "invalid-max-length";
    ErrorCodes["invalidPattern"] = "invalid-pattern";
    // Number
    ErrorCodes["unsupportedNumberType"] = "unsupported-number-type";
    ErrorCodes["notANumber"] = "not-a-number";
    ErrorCodes["notAnInteger"] = "not-an-integer";
    ErrorCodes["outOfRange"] = "out-of-range";
    ErrorCodes["invalidRange"] = "invalid-range";
    // Boolean
    ErrorCodes["notABool"] = "not-a-bool";
    // DateTime
    ErrorCodes["invalidDateTime"] = "invalid-datetime";
})(ErrorCodes || (ErrorCodes = {}));
exports.default = ErrorCodes;
