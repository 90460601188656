"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _InternetObjectError_positionRange;
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Represents the base error class in InternetObject.
 */
class InternetObjectError extends Error {
    get positionRange() { return __classPrivateFieldGet(this, _InternetObjectError_positionRange, "f"); }
    set positionRange(value) {
        __classPrivateFieldSet(this, _InternetObjectError_positionRange, value, "f");
        this.updateMessage();
    }
    /**
     * Initialize the new instance of `InternetObjectError`.
     *
     * @param errorCode {string} An error-code associated with this error
     * @param fact {string} The  reason for the error
     * @param pos {Position} The position object, for tracking line and columns. Optional
     * @param isEof {boolean} Indicates whether the error is caused by EOF. Optional
     * @param ssf {Function} The start statck function, removes the irrelavant frames from the stack trace
     */
    constructor(errorCode, fact, positionRange, isEof = false, ssf) {
        super();
        /**
         * A position object, for tracking line and columns.
         */
        _InternetObjectError_positionRange.set(this, void 0);
        this.errorCode = errorCode;
        this.fact = fact;
        __classPrivateFieldSet(this, _InternetObjectError_positionRange, positionRange, "f");
        this.isEof = isEof;
        this.name = 'InternetObjectError';
        // Format the error message
        this.updateMessage();
        // TODO: After stability, change the SSF class
        // Error.captureStackTrace(this, InternetObjectError)
        // Error.captureStackTrace(this, ssf || InternetObject)
        this.__proto__ = new.target.prototype;
    }
    updateMessage() {
        let errorMsg = `"${this.errorCode}" `;
        if (this.fact) {
            errorMsg += `"${this.fact}" `;
        }
        if (this.isEof) {
            errorMsg += `at EOF`;
        }
        else if (__classPrivateFieldGet(this, _InternetObjectError_positionRange, "f")) {
            // Handle case where position is just Position
            const startPos = __classPrivateFieldGet(this, _InternetObjectError_positionRange, "f").getStartPos();
            errorMsg += `at ${startPos.row}:${startPos.col}`;
        }
        this.message = errorMsg;
    }
}
_InternetObjectError_positionRange = new WeakMap();
exports.default = InternetObjectError;
