"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ContainerNode {
    constructor(type, children = []) {
        this.type = type;
        this.children = children;
    }
    toValue(defs) {
        return this.children.map((child) => {
            if (child) {
                return child.toValue(defs);
            }
            return undefined;
        });
    }
}
exports.default = ContainerNode;
