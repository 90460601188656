"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class MemberNode {
    constructor(value, key) {
        this.type = 'member';
        this.value = value;
        if (key) {
            this.key = key;
        }
    }
    toValue(defs) {
        if (this.key) {
            return {
                [this.key.value]: this.value.toValue(defs),
            };
        }
        else {
            return this.value.toValue(defs);
        }
    }
    getStartPos() {
        if (this.key) {
            return this.key.getStartPos();
        }
        return this.value.getStartPos();
    }
    getEndPos() {
        if (this.value) {
            return this.value.getEndPos();
        }
        if (this.key) {
            return this.key.getEndPos();
        }
        return { row: 0, col: 0, pos: 0 };
    }
}
exports.default = MemberNode;
