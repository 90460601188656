"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Literals;
(function (Literals) {
    Literals["NULL"] = "null";
    Literals["N"] = "N";
    Literals["TRUE"] = "true";
    Literals["T"] = "T";
    Literals["FALSE"] = "false";
    Literals["F"] = "F";
    Literals["Inf"] = "Inf";
    Literals["PositiveInf"] = "+Inf";
    Literals["NegativeInf"] = "-Inf";
    Literals["NaN"] = "NaN";
})(Literals || (Literals = {}));
exports.default = Literals;
