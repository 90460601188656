"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Document {
    constructor(header, sections) {
        this._header = header;
        this._sections = sections;
    }
    get header() {
        return this._header;
    }
    get sections() {
        return this._sections;
    }
    /**
     * Conerts the data sections into a JavaScript object.
     */
    toObject() {
        var _a, _b, _c, _d;
        const sectionsLen = ((_a = this._sections) === null || _a === void 0 ? void 0 : _a.length) || 0;
        let data = null;
        // Only one section
        if (sectionsLen === 1) {
            const section = (_b = this._sections) === null || _b === void 0 ? void 0 : _b.get(0);
            data = section.toObject();
        }
        // More than one section
        else {
            data = {};
            for (let i = 0; i < sectionsLen; i++) {
                const section = (_c = this._sections) === null || _c === void 0 ? void 0 : _c.get(i);
                data[section.name] = section.toObject();
            }
        }
        if ((_d = this.header.definitions) === null || _d === void 0 ? void 0 : _d.length) {
            const headerObject = this.header.toObject();
            if (headerObject) {
                return {
                    header: headerObject,
                    data
                };
            }
        }
        return data;
    }
}
exports.default = Document;
