"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toAutoString = exports.toRawString = exports.toRegularString = exports.toOpenString = void 0;
const reStructuralChars = /(?<structural>[\{\}\[\]\:\,\#\"\'\\\\~])/gm;
const escapeChars = /(?<escape>[\n\r\t])/gm;
const reNewLine = /(?<newlines>(\r\n?)|\n)/gm;
const toOpenString = (str, escapeLines) => {
    str = str.replace(reStructuralChars, '\\$1');
    if (escapeLines) {
        str = str.replace(reNewLine, '\\n');
    }
    return str;
};
exports.toOpenString = toOpenString;
const toRegularString = (str, escapeLines, encloser = '"') => {
    str = str.replace(escapeChars, '\\$1');
    if (escapeLines) {
        str = str.replace(reNewLine, '\\n');
    }
    return `${encloser}${str.replace(encloser, `\\${encloser}`)}${encloser}`;
};
exports.toRegularString = toRegularString;
const toRawString = (str, encloser = '"') => {
    return `r${encloser}${str.replace(encloser, encloser + encloser)}${encloser}`;
};
exports.toRawString = toRawString;
const toAutoString = (str, escapeLines, encloser = '"') => {
    // If the string contains any of the structural characters, then return as open string
    if (reStructuralChars.test(str)) {
        return (0, exports.toOpenString)(str, escapeLines);
    }
    // If the string contains any of the escape characters, then retrun as raw string
    if (escapeChars.test(str)) {
        return (0, exports.toRawString)(str, encloser);
    }
    // Otherwise, return as regular string
    return (0, exports.toRegularString)(str, escapeLines, encloser);
};
exports.toAutoString = toAutoString;
