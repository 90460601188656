"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Schema {
    /**
     * Creates a new instance of Internet Object Schema.
     * @param name The name of the schema
     * @param o The member definitions
     */
    constructor(name, ...o) {
        /**
         * The names of the members (properties) in the schema
         */
        this.names = [];
        /**
         * The definitions of the members (properties) in the schema
         */
        this.defs = {};
        this.name = name;
        this.names = [];
        this.defs = {};
        this.open = false;
        o.forEach((item) => {
            const key = Object.keys(item)[0];
            const value = item[key];
            if (value.path === undefined)
                value.path = key;
            this.names.push(key);
            this.defs[key] = value;
        });
    }
    /**
     * Returns the member definition of the given member name.
     * @param name The name of the member
     */
    get(name) {
        return this.defs[name];
    }
}
exports.default = Schema;
