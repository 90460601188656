"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
* Represents a parsed token.
*/
class Token {
    constructor() {
        this.pos = -1;
        this.row = -1;
        this.col = -1;
        this.token = '';
        this.value = undefined;
        this.type = '';
        this.subType = '';
    }
    /**
     * Create a token.
     * @param {number} pos - The starting position of the token in the input.
     * @param {number} row - The row number of the token's starting position.
     * @param {number} col - The column number of the token's starting position.
     * @param {string} token - The raw text of the token from the input.
     * @param {any} value - The parsed value of the token.
     * @param {string} type - A descriptive type name for the token.
     */
    static init(pos, row, col, token, value, type, subType) {
        const t = new Token();
        t.pos = pos;
        t.row = row;
        t.col = col;
        t.token = token;
        t.value = value;
        t.type = type;
        if (subType) {
            t.subType = subType;
        }
        return t;
    }
    clone() {
        const t = new Token();
        t.pos = this.pos;
        t.row = this.row;
        t.col = this.col;
        t.token = this.token;
        t.value = this.value;
        t.type = this.type;
        t.subType = this.subType;
        return t;
    }
    getStartPos() {
        return { row: this.row, col: this.col, pos: this.pos };
    }
    /**
     * Returns the ending position (row, col, pos) of the token.
     * Useful for debugging and generating error messages.
     */
    getEndPos() {
        const text = this.token.trimEnd();
        const lines = text.split('\n');
        const lastLine = lines[lines.length - 1];
        const row = this.row + lines.length - 1;
        const col = lines.length > 1 ? lastLine.length : this.col + lastLine.length;
        const pos = this.pos + text.length;
        return { row, col, pos };
    }
}
exports.default = Token;
